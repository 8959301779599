<template>
  <div class="px-2">
    <CRow>
      <CCol sm="12">
        <Toolbars @set-action-toolbars="toolbars = $event"></Toolbars>
      </CCol>
    </CRow>
    <CRow>
      <!--<CCol sm="4" v-if="showFilter">
        <Filtrate @filter-="filter = $event"></Filtrate>
      </CCol>-->
      <CCol :sm="getCol">
        <!-- GRID -->
        <Grid v-if="showGrid" :fields="fields" :items="items" @grid-row-selected="gridRowSelected = $event"
          @grid-dbclick="gridDbclick = $event" />

        <!-- DETALHE -->
        <Detail v-else-if="!showGrid" :tracer="record">
          <template slot="default">
            <CRow>
              <CCol sm="6">
                <CInput id="component_event_tenant_id" readonly v-model="record.component_event_tenant_id">
                  <template #prepend-content><i class="fas fa-info-circle" /></template>
                </CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CSelect id="type_repository_dm" label="Tipo" :readonly="readOnly" :options="typeOptions"
                  v-model="record.type_repository_dm" @update:value="record.type_repository_dm = $event" />
              </CCol>
              <CCol sm="6">
                <CSelect id="repository_dm" label="Repositório" :readonly="readOnly" :options="repositoryOptions"
                  v-model="record.repository_dm" @update:value="record.repository_dm = $event" />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CSelect id="storage_id" label="Storage" :readonly="readOnly" :options="storageOptions"
                  v-model="record.storage_id" @update:value="record.storage_id = $event" @change="setStorage" />
              </CCol>
              <CCol sm="6">
                <CSelect id="storage_local_id" label="Local Storage" :readonly="readOnly" :options="storagLocalOptions"
                  v-model="record.storage_local_id" @update:value="record.storage_local_id = $event" @change="setStorageLocal" />
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="6">
                <CInput id="path_ds" label="Mapeamento" :readonly="readOnly" v-model="record.path_ds" />
              </CCol>
              <CCol sm="6">
                <CInput id="mask_ds" label="Máscara" :readonly="maskReadOnly" v-model="record.mask_ds" />
              </CCol>
            </CRow>

          </template>
        </Detail>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import fn from "@/js"

import Toolbars from "@/components/Toolbars";
import Grid from "@/components/FormGrid";
import Detail from "@/components/FormDetail";
//import Filtrate from "./ComponentEventTenantFilter";

export default {
  name: "ComponentEventTenant",
  props: {
    parent: Object,
  },
  components: { Toolbars, Grid, Detail, /*Filtrate*/ },
  computed: {
    showFilter() {
      return this.$store.state.showFilter;
    },
    switchesIcon() {
      return this.$store.state.switchesIcon;
    },
    getCol() {
      return this.showFilter === false ? 12 : 12;
    },
    maskReadOnly() {
      return this.record.repository_dm === 73 ? false:true
    }
  },
  data() {
    return {
      //Variaveis Fixas
      COMPONENT: {
        endPoint: "api/v01/internal/component-event-tenant",
        primaryKey: "component_event_tenant_id",
        descriptionKey: "component_event_tenant_id",
        config: {
          child: true,
          parentName: "ComponentEvent", 
          parentKey: "component_event_id",
          parentValue: "component_event_id",
          parentService: "find-all-by-component-event",
          delete: { method: "delete", atribute: null, label: null },
          allowCrud: true,
          fields: 'default',
        },
        finds: [],
      },
      //Variaveis Mutantes
      showGrid: true,
      readOnly: false,
      toolbars: {},
      filter: {},
      record: {},
      gridRowSelected: {},
      gridDbclick: {},
      fields: [],
      items: [],
      //Variaveis Componente <Select>
      typeOptions: [],
      repositoryOptions: [],
      storageOptions: [],
      storagLocalOptions: [],
    };
  },
  methods: {
    async init(){
      await this.$nextTick(() => {
        fn.initializeComponent(this)
        this.typeOptions = fn.finds.findValuesDomain(21);
        this.repositoryOptions = fn.finds.findValuesDomain(22);
        this.storageOptions = fn.finds.findAllFileStorage();
        
      })
      //Chamando metodo after após resoluções iniciais
      this.afterInit()
    },

    afterInit() {      
      fn.getRecords(this)
    },

    setInitialValues() {     
      fn.setAction(this)
    },
    setStorage() {
      this.record.mask_ds = null
      this.reloadStorageLocalOptions()
    },
    setStorageLocal() {
      this.record.mask_ds = `tasy-storage://${this.record.storage_id}.${this.record.storage_local_id}/`
    },

    reloadStorageLocalOptions() {
      this.storagLocalOptions = []
      this.storagLocalOptions = fn.finds.findAllByFileStorage(this.record.storage_id);
    },
  },
  watch: {
    toolbars: {
      immediate: true,
      handler(toolbars) {
        if(toolbars !== null && toolbars === 'add') {
          this.setInitialValues()
        } else if(toolbars !== null) {
          fn.setAction(this)
        }
      }
    },
    filter: {
      immediate: true,
      handler(filter) {
        if (Object.entries(filter).length !== 0) { 
          fn.getRecordsByFilters(this)    
        }
      }
    },
    gridRowSelected: {
      immediate: true,
      handler(gridRowSelected) {
        if (Object.entries(gridRowSelected).length !== 0) { 
          fn.loadRecord(this)    
        }
      }
    },
    parent: {
      immediate: true,
      handler(parent) {
        if(parent!== undefined && parent.parentKey === this.COMPONENT.config.parentKey) {
          this.init()
        } else {
          fn.reset(this)
        }
      }
    },
  }
}
</script>

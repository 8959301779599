<template>
  <div>
    <FormFilter @set-action-filter="action = $event">
      <template slot="default">        
        <CInput id="component_parameter_id" label="ID" v-model="filter.component_parameter_id" />
        <CInput id="parameter_number" label="Número Parâmetro" v-model="filter.parameter_number" />
        <CInput id="parameter_description" label="Descrição Parâmetro" v-model="filter.parameter_description" />
      </template>
    </FormFilter>
  </div>
</template>

<script>
import FormFilter from "@/components/FormFilter";

export default {
  name: "ComponentParameterFilter",
  components: { FormFilter },
  data() {
    return {
      action: {},
      filter: {}
    }
  },
  methods: {
    apply() { 
      this.filter.apply = true
      this.triggerEvent()     
    },
    reset() {
      this.filter = {}
      this.filter.reset = true
      this.triggerEvent()   
    },
    triggerEvent() { 
      this.$emit('filter-component-parameter', this.filter);
      this.action = {}   
      if(this.filter.reset) this.filter = {}
    }
  },
  watch: {
    action() {
      if(this.action === 'applyFilter') {
        this.apply()     
      } else if(this.action === 'resetFilter') {
        this.reset()        
      }
    }
  }
}
</script>

import { render, staticRenderFns } from "./ComponentEventTenant.vue?vue&type=template&id=48f27c30&"
import script from "./ComponentEventTenant.vue?vue&type=script&lang=js&"
export * from "./ComponentEventTenant.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports